<template>
  <div>
    <b-modal
      v-model="visible"
      :title="title"
      title-tag="h3"
      header-bg-variant="transparent"
      modal-class="custom-modal-amg"
      size="xl"
      hide-footer
      @close="closeModal"
      @hidden="closeModal"
      no-close-on-backdrop
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            {{ title }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModal"
            />
          </div>
        </div>
      </template>
      <mortgageCalculator
      :type="type"
      :client="client"/>

    </b-modal>
  </div>
</template>

<script>
import mortgageCalculator from "../components/MortgageAndSimpleCalculator.vue"

export default {
  components: {
    mortgageCalculator,
  },
  props: {
    type: {
      default: "simple",
    },
    client:{
      default: null,
    }
  },

  data() {
    return {
      visible : false,
      title: '',
    };
  },

  methods: {

    closeModal() {
      this.visible = false;
      this.$emit("close");
    },
    open() {
      this.visible = true;
      if(this.type === 'simple'){
        this.title = 'Personal Loan Payment Calculator'
      }else{
        this.title = 'Mortgage Payment Calculator'
      }
    },

  },
  created() {
    this.open();
  },
};
</script>
