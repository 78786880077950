<template>
  <!-- <b-card no-body class="mb-0 border-0 p-0"> -->
    <div class="contenedor">
        <b-row class="justify-content-center">
          <!-- 4 -->
          <b-col :cols="col_type1" >
            <b-row class="justify-content-center">
              <b-col :cols="col_type2">
              <b-card
              header = "Loan Values"
              header-bg-variant="primary"
              header-text-variant="white"
              header-class="custom-bg"
              :class="isDarkSkin? 'bg-ng': 'bg-wt'">
                <b-card-text>
                  <b-form>
                    <b-row
                    v-for="(item,index) in visibleInputs"
                    :key="index"
                    class=""
                    :style="{

                      margin: '0',
                      borderBottom: '6px solid',
                      borderColor: isDarkSkin ? '#17171a' : '#FFFFFF',
                      minHeight: '50px'
                    }"
                    >
                      <b-col
                      cols="7"
                      class = "d-flex align-items-center"
                      :style="{
                            backgroundColor: isDarkSkin?
                            (type=='mortgage' && index==5? 'rgba(107, 107, 107, 0.5)' : ''):
                            (type=='mortgage'?(index == 5 ? '#E7EFFF':'#f8f8f8'):'')
                          }"
                      >
                        <label :for="'input-'+index" style="font-size: 13.5px;">
                          {{ item.label }}
                        </label>
                      </b-col>
                      <b-col class = "d-flex justify-content-center text-align-center align-items-center"
                      :style="{
                            backgroundColor: isDarkSkin?
                            (type=='mortgage'? '#20232B':''):
                            (index != 5 ? '#f1f1f1' : '#d1e0ff')

                          }"
                      >
                        <b-form-datepicker
                        v-if="item.type === 'date'"
                        :id="'input-' + index"
                        v-model="item.value"
                        locale="en"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }"
                        :style="{
                          backgroundColor: 'transparent'
                        }"

                      ></b-form-datepicker>
                        <money
                        v-if="item.type == 'money'"
                        class="form-control"
                        v-bind="money"
                        v-model="item.value"
                        :style="{
                            backgroundColor: 'transparent'
                          }"
                        ></money>

                        <b-form-input
                          v-if="item.type == ''"
                          type="number"
                          :id="'input-'+index"
                          v-model="item.value"
                          :placeholder="item.placeholder"
                          :style="{
                            backgroundColor: 'transparent'
                          }" />

                      </b-col>
                    </b-row>
                  </b-form>
                  <b-row class="justify-content-center ">
                    <b-button variant="success" class="m-1"
                    @click="calculate()"
                    >
                      <tabler-icon icon="CalculatorIcon" />
                      Calcular
                    </b-button>
                    <b-dropdown class="m-1 " variant="danger" >
                      <template #button-content>
                        <tabler-icon icon="FileTextIcon" />
                        PDF
                      </template>
                      <b-dropdown-item @click="generatePdf()">
                        <tabler-icon
                        icon="FileTextIcon"
                        class="mr-1"/>
                              View
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="sendPdfByEmail()"
                      >
                        <tabler-icon
                          icon="NotificationIcon"
                          class="mr-1"
                        />
                        <span>Send Email</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <!-- gaaa -->
                    <b-button variant="warning"
                    class="m-1"
                    @click="resetAll()"
                    >
                      <tabler-icon icon="RefreshIcon" />
                      Reset
                    </b-button>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col :cols="col_type2">
              <b-card
              header = "Loan Summary"
              header-bg-variant="primary"
              header-class="custom-bg"
              header-text-variant="white"
              :class="isDarkSkin? 'bg-ng': 'bg-wt'">
                <b-card-text>

                    <b-row
                    v-for="(item,index) in visibleOutputs"
                    :key="index"
                    class="m-0"
                    :style="{

                      margin: '0',
                      borderBottom: '6px solid',
                      borderColor: isDarkSkin ? '#17171a' : '#FFFFFF',
                      minHeight: '50px',
                      backgroundColor:
                      type=='simple' && isDarkSkin && index==3 ? '#20232B' :''
                    }"
                    >
                      <b-col cols="8" class = "d-flex align-items-center"
                      :style="{
                            backgroundColor: isDarkSkin ?
                            (type=='mortgage' && index==5? 'rgba(107, 107, 107, 0.5)' : ''):
                            (type=='simple'? (index==3 ? '#E7EFFF' : ''):
                            (index==5 ? '#E7EFFF' : ''))
                          }">
                        <span>
                          {{ item.label }}
                        </span>
                      </b-col>
                      <b-col  cols="4" class = "d-flex align-items-center"
                      :style="{
                            backgroundColor: isDarkSkin ?
                            (type=='mortgage' ? '#20232B' : '') :
                            (type=='simple'? (index !=3? '#f1f1f1':'#d1e0ff'):
                            (index != 5 ? '#f1f1f1' : '#d1e0ff'))
                          }">

                        <span>
                          {{ item.value }}
                        </span>
                      </b-col>
                    </b-row>

                </b-card-text>
              </b-card>
            </b-col>

            </b-row>


          </b-col>
          <b-col :cols="col_type3" >
            <b-table
              ref="PaymentScheduleTable"
              :items="items"
              :fields="visibleFields"
              :busy.sync="isBusy"
              responsive="md"
              :sticky-header="type=='simple'?'60vh':'40vh'"
              show-empty
              no-provider-filtering
              :class="isDarkSkin ? 'dark': ''"
            >
            <template #cell(PMT_NO)="data">
              <div class="row-table d-flex justify-content-center">
                {{ data.item.PMT_NO}}
              </div>
            </template>
            <template #cell(PAYMENT_DATE)="data">
              <div class="row-table">
                {{ data.item.PAYMENT_DATE}}
              </div>
            </template>
            <template #cell(BEGINNING_BALANCE)="data">
              <div class="row-table">
                {{ data.item.BEGINNING_BALANCE | formatMoney}}
              </div>
            </template>

            <template #cell(SCHEDULED_PAYMENT)="data">
              <div class="row-table">
                {{ data.item.SCHEDULED_PAYMENT | formatMoney}}
              </div>
            </template>

            <template #cell(EXTRA_PAYMENT)="data">
              <div class="row-table">
                {{ data.item.EXTRA_PAYMENT | formatMoney}}
              </div>
            </template>

            <template #cell(TOTAL_PAYMENT)="data">
              <div class="row-table">
                {{ data.item.TOTAL_PAYMENT | formatMoney}}
              </div>
            </template>

            <template #cell(PRINCIPAL)="data">
              <div class="row-table">
                {{ data.item.PRINCIPAL | formatMoney}}
              </div>
            </template>

            <template #cell(INTEREST)="data">
              <div class="row-table">
                {{ data.item.INTEREST | formatMoney}}
              </div>
            </template>

            <template #cell(ENDING_BALANCE)="data">
              <div class="row-table">
                {{ data.item.ENDING_BALANCE | formatMoney}}
              </div>
            </template>

            <template #cell(CUMULATIVE_INTEREST)="data">
              <div class="row-table">
                {{ data.item.CUMULATIVE_INTEREST | formatMoney}}
              </div>
            </template>
          </b-table>

          </b-col>
        </b-row>
        <b-row class="justify-content-center">

        </b-row>

</div>
</template>


<script>
import fields from "./field.js";
import moment from 'moment';
import ServiceCalculator from "../service.calculator";



export default {
  components:{
  },
  props: {
    type: {
      default:'simple',
    },
    client:{
      default: null,
    }
  },
  data() {
    return {
      money: {
        decimal: ".",
        prefix: "$ ",
        thousands: ",",
        precision: 2,
        maxlength: 16,
      },
      enterValues: [
        {label: 'Loan amount($)', placeholder: 'Example: 200000', value: '', visible: true, type: 'money'},
        {label: 'Anual interest rate(%)', placeholder: 'Example: 5', value: '', visible: true, type: '' },
        {label: 'Loan term in years', placeholder: 'Example: 30', value: '', visible: true, type:''},
        {label: 'Payments made per year', placeholder: 'Example: 12', value: '' , visible: true,type: ''},
        {label: 'Loan repayment start date', placeholder: 'Example: 11/29/2023', value: "", visible: true,type: 'date'},
        {label: 'Optional extra payments($)', placeholder: 'Example: 100', value: '' , visible: true,type: 'money'},
      ],
      changeEnterValues: [],
      loanSummary: [
        {label: 'Scheduled payment', placeholder: 'Search by Loan amount', value: '', visible: true},
        {label: 'Scheduled number of payments', placeholder: 'Search by Loan amount', value: '', visible: true},
        {label: 'Actual number of payments', placeholder: 'Search by Loan amount', value: '', visible: true},
        {label: 'Years saved off original loan term', placeholder: 'Search by Loan amount', value: '', visible: true},
        {label: 'Total early payments', placeholder: 'Search by Loan amount', value: '', visible: true},
        {label: 'Total interest ', placeholder: 'Search by Loan amount', value: '', visible: true},
        {label: 'Total cost of loan ', placeholder: 'Search by Loan amount', value: '', visible: true},
      ],
      fields: fields,
      items: [],
      isBusy: false,
      showModalAction: false,
      status: false,
      name_pdf: '',
      url_pdf: '',

      col_type1: 12,
      col_type2: 6,
      col_type3: '',
      addSmsModal:false,
      modalSendEmail: false,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    visibleInputs() {
      return this.enterValues.filter((field) => field.visible);
    },
    visibleOutputs(){
      return this.loanSummary.filter((field) => field.visible);
    }
  },
  methods: {
    async myProvider() {
      try {
        this.validateInputs();
        this.addPreloader();

        const params = {
          LoanAmount: this.enterValues[0].value,
          InterestRate: this.enterValues[1].value,
          LoanPeriod: this.enterValues[2].value,
          PaymentsPerYear: this.enterValues[3].value,
          LoanStartDate: this.enterValues[4].value,
          ExtraPayments: this.enterValues[5].value,
        }

        const data = await ServiceCalculator.getPaymentSchedule(params);



        this.status = data.status;
        if(!data.status){
          return;
        }

        this.loanSummary[0].value = data.loanSummary.ScheduledPayment ;
        this.loanSummary[1].value = data.loanSummary.ScheduledNumberOfPayments;
        this.loanSummary[2].value = data.loanSummary.ActualNumberOfPayments;
        this.loanSummary[3].value = data.loanSummary.Years_saved_off_original_loan_term;
        this.loanSummary[4].value = data.loanSummary.TotalEarlyPayments;
        this.loanSummary[5].value = data.loanSummary.TotalInterest;
        this.loanSummary[6].value = data.loanSummary.TotalCostOfLoan;
        this.removePreloader();
        return data.rows;
      } catch (error) {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Field Invalid",
          "XIcon",
          error.message
        );
      }

    },
    simpleLoanCalculator() {

      //Column EXTRA_PAYMENT
      this.fields[4].visible = false;
      //Column TOTAL_PAYMENT
      this.fields[5].visible = false;
      //Column CUMULATIVE_INTEREST
      this.fields[9].visible = false;
      // Payments made per yearb
      this.enterValues[3].visible = false;
      this.enterValues[3].value = 12;
      // Optional extra payments
      this.enterValues[5].visible = false;
      this.enterValues[5].value = 0;
      // Actual number of payments
      this.loanSummary[2].visible = false;
      // Years saved off original loan term
      this.loanSummary[3].visible = false;
      // Total early payments
      this.loanSummary[4].visible = false;
      // Total cost of loan
      this.loanSummary[6].visible = true;
      // this.resetLoanSummary()

      this.col_type1 = 4
      this.col_type2 = 12
      this.col_type3 = 8
    },
    mortgageCalculator() {
      //Column EXTRA_PAYMENT
      this.fields[4].visible = true;
      //Column TOTAL_PAYMENT
      this.fields[5].visible = true;
      //Column CUMULATIVE_INTEREST
      this.fields[9].visible = true;
      // Payments made per yearb
      this.enterValues[3].visible = true;
      // this.enterValues[3].value = 12;
      // Optional extra payments
      this.enterValues[5].visible = true;
      // this.enterValues[5].value = 100;
      // Actual number of payments
      this.loanSummary[2].visible = true;
      // Years saved off original loan term
      this.loanSummary[3].visible = true;
      // Total early payments
      this.loanSummary[4].visible = true;
      // Total cost of loan
      this.loanSummary[6].visible = false;


      this.col_type1 = 12
      this.col_type2 = 6
      this.col_type3 = 12
    },

    async calculate(){
      this.items =await this.myProvider();
      this.refresh()
    },
    resetLoanSummary(){
      this.loanSummary.forEach(element => {
        element.value = '';
      });
    },
    resetEnterValues(){
      if(this.type =='simple'){
        this.enterValues[0].value = '';
        this.enterValues[1].value = '';
        this.enterValues[2].value = '';
        this.enterValues[3].value = 12;
        this.enterValues[4].value = new Date();
        this.enterValues[5].value = 0;
      }
      if(this.type =='mortgage'){
        this.enterValues[0].value = '';
        this.enterValues[1].value = '';
        this.enterValues[2].value = '';
        this.enterValues[3].value = '';
        this.enterValues[4].value = new Date();
        this.enterValues[5].value = '';
      }

    },
    resetAll(){

      this.resetEnterValues();
      this.items = [];
      this.loanSummary.forEach(element => {
        element.value = '';
      });
    },
    async generatePdf() {
      try {
        this.validateInputs();
        this.addPreloader();
        const params = {
          LoanAmount: this.enterValues[0].value,
          InterestRate: this.enterValues[1].value,
          LoanPeriod: this.enterValues[2].value,
          PaymentsPerYear: this.enterValues[3].value,
          LoanStartDate: this.enterValues[4].value,
          ExtraPayments: this.enterValues[5].value,
          type: this.type,
          client:this.client
        }

        const data = await ServiceCalculator.paymentSchedulePdf(params);
        this.forceFileDownloadPdfWithDownload(data, 'PaymentSchedule.pdf');
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Field Invalid",
          "XIcon",
          error.message
        );
      }
    },
    async forceFileDownloadPdfWithDownload(response, fileName) {
            const url = window.URL.createObjectURL(
                new Blob([response], {
                  type: "application/pdf",
                })
                );
            window.open(url, "_blank");
    },
    async sendPdfByEmail() {
      try {
        if(!this.client.email){
          this.showToast(
            "warning",
            "top-right",
            "Unregistered email",
            "AlertTriangleIcon",
            "This client does not have a registered email"
          );
          return;
        }
        this.addPreloader();
        this.validateInputs();

        const params = {
          LoanAmount: this.enterValues[0].value,
          InterestRate: this.enterValues[1].value,
          LoanPeriod: this.enterValues[2].value,
          PaymentsPerYear: this.enterValues[3].value,
          LoanStartDate: this.enterValues[4].value,
          ExtraPayments: this.enterValues[5].value,
          type: this.type,
          id: this.client.client_account_id,
          modul : 11,
          client:this.client
        }
        await ServiceCalculator.sendPaymentScheduleByEmail(params);
        this.removePreloader();
        this.showSuccessSwal("The payment schedule has been sent");
      } catch (error) {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Field Invalid",
          "XIcon",
          error.message
        );
      }
    },
     refresh() {
      this.$refs.PaymentScheduleTable.refresh();
    },
    validateInputs() {
      const LoanAmount =  Number(this.enterValues[0].value)
      const InteresRate =  Number(this.enterValues[1].value)
      const LoanPeriod =  Number(this.enterValues[2].value)
      const PaymentsPerYear =  Number(this.enterValues[3].value)
      const LoanStartDate =  moment(this.enterValues[4].value).isValid()
      const ExtraPayments =  Number(this.enterValues[5].value)
      if (isNaN(LoanAmount) || LoanAmount <= 0){
        this.enterValues[0].value = ''
        throw new Error("Loan amount is invalid");
      }
      if (isNaN(InteresRate) || InteresRate <= 0){
        this.enterValues[1].value = ''
        throw new Error("Annual interest rate is invalid");
      }
      if (isNaN(LoanPeriod) || LoanPeriod <= 0){
        this.enterValues[2].value = ''
        throw new Error("Loan term in years is invalid");
      }
      if (isNaN(PaymentsPerYear) || PaymentsPerYear <= 0){
        this.enterValues[3].value = ''
        throw new Error("Loan Period is invalid");
      }
      if (!LoanStartDate){
        throw new Error("Loan Start Date is invalid");
      }
      if (isNaN(ExtraPayments) || ExtraPayments < 0){
        this.enterValues[5].value = ''
        throw new Error("Extra Payments is invalid");
      }
    },
  },
  created(){
    if(this.type =='simple'){
      this.simpleLoanCalculator();
    }
    if(this.type =='mortgage'){
      this.mortgageCalculator();
    }
    let newDate =new Date();
    newDate.setMonth(newDate.getMonth() + 1).toString();
    this.enterValues[4].value = newDate;

  }
};
</script>
<style lang="scss">
* {
  --primary-color: #3f7afa;
}
 /* nigga #111113  */
 /* #20232B */
 .custom-bg {
  background-color: var(--primary-color) !important;
  color: white !important;
 }
  
 .form .row .col{
  border: 1px solid #ccc !important;
  padding: 0;
  }
 .input-prepend-width {

    width: 200px;
  }
  .contenedor .card{
    box-shadow: none;

  }
  .contenedor .bg-ng{
    background-color: #111113 !important;
  }
  .contenedor .bg-wt{
    background-color: #f9f9f9 !important;
  }
  .contenedor .card .card-header{
    padding: 9.72px 27px;

  }
  .contenedor .card .card-body{
    padding: 0;
  }

  .contenedor .table thead tr th{
    background-color: var(--primary-color) !important;
    border: none;
    color: rgb(255, 255, 255) !important;
    text-align: center; /* Alineación horizontal */
    vertical-align: middle; /* Alineación vertical */
  }
  .contenedor thead th:first-child {

    border-top-left-radius: 4.778px;
  }
  .contenedor thead th:last-child {

    border-top-right-radius: 4.778px;
  }


  .contenedor {
    .table > tbody > tr > td {
      border-top: none !important;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 3px;
      padding-bottom: 3px;
      position: relative;
    }

    .table > tbody > tr > td:nth-child(4) > div {
      background-color: #d1e0ff;
    }
    .table > tbody > tr > td:first-child div {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .table > tbody > tr > td:last-child div {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .table > thead > tr > th {
      background: var(--primary-color) !important;
      color: white !important;
      border-top: none !important;
      border-bottom: none !important;
    }

    .table .row-table {
      background-color: #f1f1f1;
      padding: 1.5rem 0.75rem;
      font-size: 0.857rem;
      letter-spacing: 0.5px;
    }
    .dark .table > tbody > tr > td:nth-child(4) > div {
      background-color: #20232B;
    }
    .dark .table > tbody {
      background: #17171a !important;
    }
    .dark .table .row-table {
      background-color: #111113;
    }
  }
</style>
