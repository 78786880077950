export default [
  {
    key: "PMT_NO",
    label: "No",
    class: "text-left",
    visible: true,
  },
  {
    key: "PAYMENT_DATE",
    label: "Payment date",
    class: "text-center",
    visible: true,
  },
  {
    key: "BEGINNING_BALANCE",
    label: "Beginning balance",
    class: "text-center",
    visible: true,
  },
  {
    key: "SCHEDULED_PAYMENT",
    label: "Scheduled payment",
    class: "text-center",
    visible: true,
  },
  {
    key: "EXTRA_PAYMENT",
    label: "Extra payment",
    class: "text-center",
    visible: true,
  },
  {
    key: "TOTAL_PAYMENT",
    label: "Total payment",
    class: "text-center",
    visible: true,
  },
   {
    key: "PRINCIPAL",
    label: "Principal",
    class: "text-center",
    thClass: "px-1",
    visible: true,
  },
  {
    key: "INTEREST",
    label: "Interest",
    class: "text-center",
    visible: true,
  },
  {
    key: "ENDING_BALANCE",
    label: "Ending balance",
    class: "text-center",
    visible: true,
  },
  {
    key: "CUMULATIVE_INTEREST",
    label: "Cumulative interest",
    class: "text-center",
    visible: true,
  },
];
