<template>
  <b-modal
    ref="modalName"
    modal-class="custom-modal-amg"
    title-class="h4 text-white text-uppercase"
    title="Auto Loan Payment Calculator"
    size="xl"
    @hidden="closeModal()"
    hide-footer
    header-bg-variant="transparent"
    header-class="p-0"
    centered
    no-close-on-backdrop
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Auto Loan Payment Calculator
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <div class="form-calculator-modal" :class="isDarkSkin ? 'isDarkSkin' : ''">
      <b-row>
        <b-col cols="4">
          <b-card
            header="Inputs"
            header-class="header-input"
            header-bg-variant="primary"
            header-text-variant="white"
            class="m-0"
          >
            <template #header>
              <h5 class="m-0 text-white">
                <tabler-icon icon="CashIcon" />
                <label for="" class="text-white m-0">Inputs</label>
              </h5>
            </template>
            <b-card-text>
              <b-form>
                <b-row class="my-1" v-for="row in 3" :key="row">
                  <template v-for="(item, index) in formInput">
                    <b-col :sm="item.col" v-if="row === item.row" :key="index">
                      <label :for="'input-small' + index" class="m-0">
                        {{ item.name }}
                      </label>
                      <b-form-input
                        v-if="item.type === 'input'"
                        type="number"
                        :id="'input-' + index"
                        placeholder="0.00"
                        v-model="item.model"
                      ></b-form-input>

                      <b-form-datepicker
                        v-if="item.type === 'date'"
                        :id="'input-' + index"
                        v-model="item.model"
                        :locale="item.locale"
                        :date-format-options="item.dateFormatOptions"
                      ></b-form-datepicker>

                      <v-select
                        v-if="item.type === 'select'"
                        v-model="item.model"
                        :reduce="(opt) => opt.value"
                        :options="item.dataSelect"
                        label="text"
                      ></v-select>
                      <money
                        v-if="item.type === 'money'"
                        class="form-control"
                        v-bind="money"
                        v-model="item.model"
                      ></money>
                    </b-col>
                  </template>
                  <template>
                    <b-col sm="3" v-if="row === 3">
                      <label class="m-0 d-block" style="color: transparent"
                        >-</label
                      >
                      <b-button
                        :variant="isDarkSkin ? 'outline-primary' : 'primary'"
                        @click="calculator"
                        class="btn-block pl-1 pr-1"
                      >
                        <tabler-icon icon="CalculatorIcon" />
                        Calculate
                      </b-button>
                    </b-col>
                    <b-col sm="3" v-if="row === 3">
                      <label class="m-0 d-block" style="color: transparent"
                        >-</label
                      >
                      <b-dropdown
                      :variant="isDarkSkin ? 'outline-danger' : 'danger'"
                      class = "btn-block">
                        <template #button-content>
                          <tabler-icon icon="FileTextIcon" />
                          PDF
                        </template>
                        <b-dropdown-item @click="previewPdf">
                          <tabler-icon
                          icon="FileTextIcon"
                          class="mr-1"/>
                                View
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="sendPdfByEmail"
                        >
                          <tabler-icon
                            icon="NotificationIcon"
                            class="mr-1"
                          />
                          <span>Send Email</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </template>
                </b-row>
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card
            header="Summary"
            header-class="header-input"
            header-bg-variant="primary"
            header-text-variant="white"
            class="m-0"
          >
            <template #header>
              <h5 class="m-0 text-white">
                <tabler-icon icon="ClipboardListIcon" />
                <label for="" class="text-white m-0"
                  >Summary (with no extra payments)</label
                >
              </h5>
            </template>
            <b-card-text class="details">
              <b-row class="my-1">
                <b-col sm="12">
                  <label for="" class="m-0">Number of Payments</label>
                  <b-form-input
                    readonly
                    :value="results.summary.numberOfPayments"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col sm="6">
                  <label for="" class="m-0">Rate (per period)</label>
                  <b-form-input
                    readonly
                    :value="results.summary.ratePerPeriod + '%'"
                  ></b-form-input>
                </b-col>
                <b-col sm="6">
                  <label for="" class="m-0">Payment (per period)</label>
                  <b-form-input
                    readonly
                    :value="formatCalculator(results.summary.paymentPerPeriod)"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col sm="6">
                  <label for="" class="m-0">Total Interest</label>
                  <b-form-input
                    readonly
                    :value="formatCalculator(results.summary.totalInterest)"
                  ></b-form-input>
                </b-col>
                <b-col sm="6">
                  <label for="" class="m-0">Total Payments</label>
                  <b-form-input
                    readonly
                    :value="formatCalculator(results.summary.totalPayments)"
                  ></b-form-input>
                </b-col>
              </b-row>
              <!-- <b-row class="my-1">
              <b-col sm="12" v-if="results.summary.message_invalid">
                <span class="text-danger float-right">{{
                  results.summary.message_invalid
                }}</span>
              </b-col>
            </b-row> -->
              <!-- <b-row class="my-1">
              <b-col sm="12">

              </b-col>
            </b-row> -->
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card
            header="Effect of Extra Payments"
            header-class="header-input"
            header-bg-variant="primary"
            header-text-variant="white"
            class="m-0"
          >
            <template #header>
              <h5 class="m-0 text-white">
                <tabler-icon icon="TableIcon" />
                <label for="" class="text-white m-0"
                  >Effect of Extra Payments</label
                >
              </h5>
            </template>
            <b-card-text class="details">
              <b-row class="my-1">
                <b-col sm="12">
                  <label for="" class="m-0">Number of Payments</label>
                  <b-form-input
                    readonly
                    :value="results.effectOffExtraPayments.numberOfPayments"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col sm="6">
                  <label for="" class="m-0">Total Interest</label>
                  <b-form-input
                    readonly
                    :value="
                      formatCalculator(
                        results.effectOffExtraPayments.totalInterest
                      )
                    "
                  ></b-form-input>
                </b-col>
                <b-col sm="6">
                  <label for="" class="m-0">Reduced Interest</label>
                  <b-form-input
                    readonly
                    :value="
                      results.effectOffExtraPayments.reduceInterest === 'none'
                        ? 'none'
                        : formatCalculator(
                            results.effectOffExtraPayments.reduceInterest
                          )
                    "
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col sm="6">
                  <label for="" class="m-0">Total Payments</label>
                  <b-form-input
                    readonly
                    :value="
                      formatCalculator(
                        results.effectOffExtraPayments.totalPayments
                      )
                    "
                  ></b-form-input>
                </b-col>
                <b-col sm="6">
                  <label for="" class="m-0">Last Payment Date</label>
                  <b-form-input
                    readonly
                    :value="results.effectOffExtraPayments.lastPaymentDate"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="4">
          <b-card
            header="Chart Balance"
            header-class="header-input"
            header-bg-variant="primary"
            header-text-variant="white"
            class="m-0"
          >
            <template #header>
              <h5 class="m-0 text-white">
                <tabler-icon icon="ChartLineIcon" />
                <label for="" class="text-white m-0">Chart Balance</label>
              </h5>
            </template>
            <b-card-text class="pt-1">
              <div id="chart">
                <VueApexCharts
                  type="line"
                  height="320"
                  :options="chartOptions"
                  :series="series"
                  ref="chartPayments"
                ></VueApexCharts>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="8">
          <b-table
            :fields="fields"
            small
            :items="results.dataCalculations"
            class="table-new-customization custom-table"
          >
            <template #cell(nro)="{ item }">
              <div class="row-table text-center">
                {{
                  item.nro === 0
                    ? "-"
                    : item.nro < 10
                    ? `0${item.nro}`
                    : item.nro
                }}
              </div>
            </template>
            <template #cell(due_date)="{ item }">
              <!-- {{ item.nro === 0 ? "" : item.due_date | myGlobal }} -->
              <div class="row-table" v-if="item.nro != 0">
                {{ item.due_date | myGlobal }}
              </div>
              <div v-else class="row-table">-</div>
            </template>
            <template #cell(payment_due)="{ item }">
              <div class="row-table">
                {{ item.nro === 0 ? "-" : formatCalculator(item.payment_due) }}
              </div>
            </template>
            <template #cell(additional_payment)="{ item }">
              <div class="row-table">
                {{
                  item.nro === 0
                    ? "-"
                    : formatCalculator(item.additional_payment)
                }}
              </div>
            </template>
            <template #cell(interest)="{ item }">
              <div class="row-table">
                {{ item.nro === 0 ? "-" : formatCalculator(item.interest) }}
              </div>
            </template>
            <template #cell(principal)="{ item }">
              <div class="row-table">
                {{ item.nro === 0 ? "-" : formatCalculator(item.principal) }}
              </div>
            </template>
            <template #cell(balance)="{ item }">
              <div class="row-table">
                {{ formatCalculator(item.balance) }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import ServiceCalculator from "../service.calculator";
export default {
  name: "AutoLoanPaymentCalculatorModal",
  props: {
    client:{
      default: null,
    }
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      modalLoan: false,
      money: {
        decimal: ".",
        prefix: "$ ",
        thousands: ",",
        precision: 2,
        maxlength: 16,
      },
      srcChart: "",
      formInput: {
        autoLoanAmount: {
          name: "Auto Loan Amount:",
          model: "1.00",
          type: "money",
          col: 6,
          row: 1,
        },
        annualInterestRate: {
          name: "Annual Interest Rate:",
          model: "14",
          type: "input",
          col: 6,
          row: 1,
        },
        termOfLoanInYears: {
          name: "Term of Loan in Years:",
          model: "6",
          type: "input",
          col: 6,
          row: 2,
        },
        firstPaymentDate: {
          name: "First Payment Date:",
          model: null,
          type: "date",
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          },
          col: 6,
          row: 2,
        },
        frequencyOfPayment: {
          name: "Frequency of Payment:",
          model: "MONTHLY",
          type: "select",
          dataSelect: [
            { text: "Annual", value: "ANNUAL" },
            { text: "Semi-Annual", value: "SEMI-ANNUAL" },
            { text: "Quarterly", value: "QUARTERLY" },
            { text: "Bi-Monthly", value: "BI-MONTHLY" },
            { text: "Monthly", value: "MONTHLY" },
            { text: "Semi-Monthly", value: "SEMI-MONTHLY" },
            { text: "Bi-Weekly", value: "BI-WEEKLY" },
          ],
          col: 6,
          row: 3,
        },
      },
      bloImageChartLine: null,
      results: {
        summary: {
          numberOfPayments: null,
          ratePerPeriod: null,
          paymentPerPeriod: null,
          totalInterest: null,
          totalPayments: null,
          message_invalid: null,
        },
        effectOffExtraPayments: {
          totalPayments: null,
          totalInterest: null,
          reduceInterest: null,
          numberOfPayments: null,
          lastPaymentDate: null,
        },
        dataCalculations: [],
        xAxis: 0,
        series: 0,
      },
      fields: [
        { key: "nro", label: "Nro", thClass: "text-center" },
        {
          key: "due_date",
          label: "Due Date",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "payment_due",
          label: "Payment Due",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "additional_payment",
          label: "Additional Payment",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "interest",
          label: "Interest",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "principal",
          label: "Principal",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "balance",
          label: "Balance",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      series: [
        {
          name: "Extra balance",
          data: [],
        },
        {
          name: "Balance",
          data: [], // Ajusta la longitud según tu necesidad
        },
      ],
      chartOptions: {
        colors: ["#3FC8FF", "#3F7AFA"],
        chart: {
          height: 250,
          type: "line",
          zoom: {
            enabled: false,
          },
          id: "chartLinePayments",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 5],
          curve: "straight",
          dashArray: [0],
        },
        tooltip: {
          enabled: true, // Desactiva el tooltip
        },
        legend: {
          floating: true,
          horizontalAlign: "right",
          position: "top",
          offsetX: 0,
          offsetY: 0,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        xaxis: {
          type: "numeric",
          title: {
            text: "Payment Number",
            offsetY: 10,
          },
          labels: {
            formatter: function (value, timestamp, index) {
              if (!value) return value;
              return value.toFixed(0);
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#5e5873",
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  watch: {
    results: {
      handler(value) {
        this.series = value.series;
      },
      deep: true,
    },
    series(values) {
      if (values.length > 0) {
        this.$nextTick(() => {
          this.chartOptions = {
            xaxis: {
              min: 0,
              max: this.results.xAxis,
              tickAmount:
                this.results.xAxis > 10 ? undefined : this.results.xAxis,
            },
          };
        });
      }
    },
    isDarkSkin(value) {
      this.changeStyleChartLine(value);
    },
  },
  mounted() {
    this.toggleModal("modalName");
    this.$nextTick(async () => {
      await this.calculator();
    });
  },
  created() {
    let newDate =new Date();
    newDate.setMonth(newDate.getMonth() + 1);
    this.formInput.firstPaymentDate.model = newDate;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async calculator() {
      try {
        this.validateInputs();
        this.addPreloader();
        const data = await ServiceCalculator.autoLPayCalculator({
          autoLoanAmount: Number(this.formInput.autoLoanAmount.model),
          annualInterestRate: Number(this.formInput.annualInterestRate.model),
          loanTermInYears: Number(this.formInput.termOfLoanInYears.model),
          firstPaymentDate: moment(this.formInput.firstPaymentDate.model).format("YYYY-MM-DD"),
          paymentFrequency: this.formInput.frequencyOfPayment.model,
        });
        this.results = data;
        this.changeStyleChartLine(this.isDarkSkin);
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Field Invalid",
          "XIcon",
          ex.message
        );
      }
    },
    validateInputs() {
      const autoLoanAmount = Number(this.formInput.autoLoanAmount.model);
      const termOfLoanInYears = Number(this.formInput.termOfLoanInYears.model);
      const annualInterestRate = Number(
        this.formInput.annualInterestRate.model
      );
      const firstPaymentDate = this.formInput.firstPaymentDate;
      const isValidDate = moment(firstPaymentDate).isValid();
      const frequencyOfPayment = this.formInput.frequencyOfPayment.model;
      if (isNaN(autoLoanAmount) || autoLoanAmount === 0)
        throw new Error("Auto Loan Amount is invalid");
      if (isNaN(annualInterestRate) || annualInterestRate === 0)
        throw new Error("Annual Interest Rate is invalid");
      if (isNaN(termOfLoanInYears) || termOfLoanInYears === 0)
        throw new Error("Term of loan in years is invalid");
      if (!frequencyOfPayment || frequencyOfPayment == "")
        throw new Error("Frecuency of Payment is invalid");

      if (!isValidDate) throw new Error("First Payment Date is invalid");
    },
    formatCalculator(value) {
      const formater = new Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
      }).format(Number(value));
      return formater;
    },
    async previewPdf() {
      try {
        this.changeStyleChartLine(false);
        this.addPreloader();
        setTimeout(() => {
          this.processingPreviewPdf();
        }, 200);
      } catch (ex) {
        this.removePreloader();
        console.log(ex);
      }
    },
    async processingPreviewPdf() {
      await this.setImage();
      const formData = new FormData();
      formData.append(
        "imageChart",
        this.bloImageChartLine,
        "chartLinePayments.png"
      );
      const form = {
        autoLoanAmount: Number(this.formInput.autoLoanAmount.model),
        annualInterestRate: Number(this.formInput.annualInterestRate.model),
        loanTermInYears: Number(this.formInput.termOfLoanInYears.model),
        firstPaymentDate: moment(this.formInput.firstPaymentDate.model).format('YYYY-MM-DD'),
        paymentFrequency: this.formInput.frequencyOfPayment.model,
        client:JSON.stringify(this.client)
      };
      for (let key in form) {
        formData.append(key, form[key]);
      }
      const data = await ServiceCalculator.autoLPayCalPdf(formData);
      const _blob = new Blob([data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(_blob);
      window.open(url, "_blank");
      this.removePreloader();
      this.changeStyleChartLine(this.isDarkSkin);
    },
    async sendPdfByEmail(){
      try {
        if(!this.client.email){
          this.showToast(
            "warning",
            "top-right",
            "Unregistered email",
            "AlertTriangleIcon",
            "This client does not have a registered email"
          );
          return;
        }
        this.addPreloader();
        this.changeStyleChartLine(false);
        setTimeout(async () => {
            await this.setImage();
            const formData = new FormData();
            formData.append(
              "imageChart",
              this.bloImageChartLine,
              "chartLinePayments.png"
            );
            const form = {
              autoLoanAmount: Number(this.formInput.autoLoanAmount.model),
              annualInterestRate: Number(this.formInput.annualInterestRate.model),
              loanTermInYears: Number(this.formInput.termOfLoanInYears.model),
              firstPaymentDate: moment(this.formInput.firstPaymentDate.model).format('YYYY-MM-DD'),
              paymentFrequency: this.formInput.frequencyOfPayment.model,
              id: this.client.client_account_id,
              modul: 11,
              client:JSON.stringify(this.client)
            };
            for (let key in form) {
              formData.append(key, form[key]);
            }
            await ServiceCalculator.sendPdfAutoLoanByEmail(formData);
            this.removePreloader();
            this.showSuccessSwal("The payment schedule has been sent");
          }, 200);

      } catch (error) {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Field Invalid",
          "XIcon",
          error.message
        );
      }
    },
    changeStyleChartLine(dark) {
      const color = dark ? "#FFF" : "#5e5873";
      this.chartOptions = {
        yaxis: {
          labels: {
            style: {
              colors: color,
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: color,
            },
          },
          title: {
            style: {
              color: color,
            },
          },
        },
        legend: {
          labels: {
            colors: color,
          },
        },
        tooltip: {
          theme: dark ? "dark" : "light",
        },
      };
    },
    async setImage() {
      if (this.$refs.chartPayments) {
        const chartId = this.$refs.chartPayments.chart.w.config.chart.id;
        const ApexChartVue = window.Apex._chartInstances;
        const chartLinePayments = ApexChartVue.find(
          (chart) => chart.id === chartId
        );
        const base64 = await chartLinePayments.chart.dataURI();
        if (base64.imgURI) {
          this.srcChart = base64.imgURI;
          const byteCharacters = atob(base64.imgURI.split(",")[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/png" });
          this.bloImageChartLine = blob;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.dark-header {
  // background-color: #3f7afa!important;
}
.form-calculator-modal.isDarkSkin {
  .card,
  .form-control,
  .vs__dropdown-toggle,
  .table .row-table {
    background: #111113 !important;
  }
  .table > tbody > tr:not(tr:first-child) > td:nth-child(4) > div {
    background-color: #10317066 !important;
    color: #3f7afa;
    font-weight: bold;
  }
  .table > tbody > tr:first-child div {
    background-color: #a4c3ff !important;
  }
}
.form-calculator-modal {
  .header-input {
    padding: 5px !important;
    font-weight: bold;
    text-transform: capitalize;
  }
  label {
    font-weight: 600;
  }
  .card-header.bg-primary {
    background-color: #3f7afa !important;
    padding: 0.3rem 0.5rem;
  }
  hr {
    border-top: 3px solid #3f7afa;
  }
  .table {
    border-spacing: 10px;
  }
  .table > tbody > tr > td {
    border-top: none !important;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
  }
  .table > tbody > tr > td:first-child {
    padding-left: 0px;
  }
  .table > tbody > tr:first-child div {
    background-color: #a4c3ff !important;
    color: #3f7afa;
    font-weight: bold;
  }
  .table
    > tbody
    > tr:first-child
    > td:not(tr:nth-child(1) td:nth-last-child(1))
    > div {
    color: transparent !important;
  }
  .table > tbody > tr:not(tr:first-child) > td:nth-child(4) > div {
    background-color: #d1e0ff;
  }
  //   .dark .table > tbody > tr:not(tr:first-child) > td:nth-child(4) > div {
  //     background-color: #103170;
  //   }
  .table > tbody > tr > td:first-child div {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .table > tbody > tr > td:last-child div {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .table > thead > tr > th {
    background: #3f7afa !important;
    color: white !important;
    border-top: none !important;
    border-bottom: none !important;
    padding: 0.4rem 0.5rem !important;
  }
  .table > thead > tr > th:first-child {
    border-top-left-radius: 8px;
  }
  .table > thead > tr > th:last-child {
    border-top-right-radius: 8px;
  }

  .table tbody tr:first-child td .row-table {
    padding: 0.4rem 0.75rem;
  }
  // .table .row-table {
  //   background-color: #f1f1f1;
  //   padding: 0.65rem 0.75rem;
  //   font-size: 0.857rem;
  //   letter-spacing: 0.5px;
  // }
  input.form-control[readonly="readonly"] {
    cursor: not-allowed;
  }
}
</style>
