const { amgApi } = require("@/service/axios");

class ServiceCalculator {

    /** AutoLoanPaymentCalculator */
    async autoLPayCalculator(params) {
        const { data } = await amgApi.post('/specialists/get-auto-loan-payment-calculator', params);
        return data;
    }

    /**AutoLoanPaymentCalculatorPDF */
    async autoLPayCalPdf(params) {
        const { data } = await amgApi.post('/specialists/pdf-calculator', params, {
            responseType: "blob",
        });
        return data;
    }

    async sendPdfAutoLoanByEmail(params){
      const { data } = await amgApi.post(
        "/specialists/send-pdf-autoloan-by-email",params
      );
      return data;
    }

    // Simple and Mortgage Calculators
    async getPaymentSchedule(params) {
        const { data } = await amgApi.post(
          "/specialists/get-payment-schedule",params
        );
        return data;
    }
    async paymentSchedulePdf(params){
      const { data } = await amgApi.post(
        "/specialists/payment-schedule-pdf",params,{responseType: 'blob'}
      );
      return data;
    }
    async sendPaymentScheduleByEmail(params){
      const { data } = await amgApi.post(
        "/specialists/send-payment-schedule-by-email",params
      );
      return data;
    }
}
export default new ServiceCalculator();
